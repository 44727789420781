import * as iots from "io-ts";
import React, { useState } from "react";
import { t } from "ttag";

import { CRMForm, FormType } from ".";
import { allocateBuilderID } from "../../api/builders";
import { ButtonPrimary } from "../Clickables";
import { TextField } from "../Forms/Input";
import { Select, SelectItem } from "../Forms/Select";
import { Svg } from "../Svg";

import styles from "./forms.module.scss";

export const LGBuilderRegistrationForm = () => {
    // Generate a builder ID, but only on the client-side. This prevents it from
    // being cached by the CDN.
    const [builderID, setBuilderID] = useState("");

    // When the user copies the build ID, change the copy icon to a "success"
    // checkmark for a couple seconds to inform them that it worked.
    const [copiedID, setCopiedID] = useState(false);
    const onCopyBuilderID = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        await navigator.clipboard.writeText(builderID);
        setCopiedID(true);
        setTimeout(() => {
            setCopiedID(false);
        }, 2_000);
    };

    // Right before submit, allocate an RBN and add it to the form data.
    const tweakFormDataBeforeSubmit = async (
        data: FormData,
    ): Promise<FormData> => {
        const maybeCompanyName = data.get("company_name");
        const companyName = iots.string.is(maybeCompanyName)
            ? maybeCompanyName
            : "";
        const builder = await allocateBuilderID(companyName);
        setBuilderID(builder.number);
        data.set("builder_id", builder.number);
        return data;
    };

    const successState = (
        <>
            <h2 className={styles.title}>{t`Thank you for contacting us!`}</h2>
            <p>
                {t`Your message was sent and we will be in touch with you shortly.`}
            </p>
            <p>{t`For reference, your SKS Builder ID is:`}</p>
            <div className={styles.code}>
                <code>{builderID}</code>
                <ButtonPrimary
                    onClick={onCopyBuilderID}
                    className={styles.copyClickable}
                >
                    <Svg
                        name={copiedID ? "check" : "copy"}
                        visuallyHiddenText={t`Copy to Clipboard`}
                        className={styles.copySvg}
                    />
                </ButtonPrimary>
            </div>
        </>
    );

    return (
        <CRMForm
            layout="three-column"
            type={FormType.LG_BUILDER_REGISTRATION}
            tweakFormDataBeforeSubmit={tweakFormDataBeforeSubmit}
            successState={successState}
        >
            {(disabled, legal) => (
                <>
                    <div className={styles.threeColumnTitle}>
                        {t`Builder info`}
                    </div>
                    <div className={styles.threeColumnGroup}>
                        <TextField
                            label={t`Builder First Name`}
                            className={styles.input}
                            name="first_name"
                            isRequired
                        />
                        <TextField
                            label={t`Builder Last Name`}
                            className={styles.input}
                            name="last_name"
                            isRequired
                        />
                        <TextField
                            label={t`Builder Email Address`}
                            className={styles.input}
                            name="email_address"
                            isRequired
                            type="email"
                        />
                        <Select
                            label={t`Profession`}
                            name="other"
                            isRequired
                            placeholder={t`Select an item`}
                            className={styles.input}
                        >
                            <SelectItem id="Builder">{t`Builder`}</SelectItem>
                            <SelectItem id="Designer">{t`Designer`}</SelectItem>
                            <SelectItem id="Architect">{t`Architect`}</SelectItem>
                        </Select>
                        <TextField
                            label={t`Builder Company Name`}
                            className={styles.input}
                            name="company_name"
                            isRequired
                        />
                        <TextField
                            label={t`Builder Street Address`}
                            className={styles.input}
                            name="address"
                            isRequired
                        />
                        <TextField
                            label={t`Builder City`}
                            className={styles.input}
                            name="city"
                            isRequired
                        />
                        <TextField
                            label={t`Builder State`}
                            className={styles.input}
                            name="state"
                            isRequired
                        />
                        <TextField
                            label={t`Builder ZIP Code`}
                            className={styles.input}
                            name="zip_code"
                            isRequired
                        />
                        <TextField
                            label={t`Builder Website/URL`}
                            className={styles.input}
                            name="website"
                            isRequired
                        />
                        <TextField
                            label={t`Business License #`}
                            className={styles.input}
                            name="company_license"
                            isRequired
                        />
                        <TextField
                            label={t`Builder Company Phone`}
                            className={styles.input}
                            name="phone"
                        />
                        <TextField
                            label={t`Dealer Name`}
                            className={styles.input}
                            name="dealer"
                            isRequired
                        />
                        <Select
                            label={t`Types of Projects`}
                            name="what_type_of_homes_do_they_build"
                            isRequired
                            placeholder={t`Select an item`}
                            className={styles.input}
                        >
                            <SelectItem id="Custom">{t`Custom`}</SelectItem>
                            <SelectItem id="Semi-Custom">{t`Semi-Custom`}</SelectItem>
                            <SelectItem id="Spec homes">{t`Spec homes`}</SelectItem>
                        </Select>
                        <Select
                            label={t`Number of Projects`}
                            name="how_many_homes_annually"
                            isRequired
                            placeholder={t`Select an item`}
                            className={styles.input}
                        >
                            <SelectItem id="1-10">{t`1 – 10`}</SelectItem>
                            <SelectItem id="11-50">{t`11 – 50`}</SelectItem>
                            <SelectItem id="50+">{t`50+`}</SelectItem>
                        </Select>
                    </div>
                    <div className={styles.threeColumnTitle}>
                        {t`Requestor info`}
                    </div>
                    <div className={styles.threeColumnGroup}>
                        <Select
                            label={t`Requesting for Someone Else?`}
                            name="requesting_for_someone_else"
                            isRequired
                            placeholder={t`Select an item`}
                            className={styles.input}
                        >
                            <SelectItem id="Yes">{t`Yes`}</SelectItem>
                            <SelectItem id="No">{t`No`}</SelectItem>
                        </Select>
                        <TextField
                            label={t`Requestor Name`}
                            className={styles.input}
                            name="requestor_name"
                        />
                        <TextField
                            label={t`Requestor Email Address`}
                            className={styles.input}
                            type="email"
                            name="requestor_email_address"
                        />
                    </div>
                    <div className={styles.submitContainer}>
                        {legal}
                        <ButtonPrimary disabled={disabled} type="submit">
                            {t`Register`}
                        </ButtonPrimary>
                    </div>
                </>
            )}
        </CRMForm>
    );
};
