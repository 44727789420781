import React from "react";
import { t } from "ttag";

import { hooks } from "@thelabnyc/thelabui/src/components/ScrollNavMenuBlock";
import { classNames } from "@thelabnyc/thelabui/src/utils/styles";

import { ButtonSecondary } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./MenuToggle.module.scss";

export const MenuToggle = (props: {
    isOpen: boolean;
    onToggle: () => void;
}) => {
    const activeItem = hooks.useActiveNavItem();
    return (
        <div className={styles.content}>
            <ButtonSecondary
                className={classNames([
                    [styles.menuToggle, true],
                    [styles.isOpen, props.isOpen],
                ])}
                onClick={props.onToggle}
            >
                {activeItem ? activeItem.name : t`Jump To…`}
                <Svg name="caret-right" className={styles.toggleIcon} />
            </ButtonSecondary>
        </div>
    );
};
