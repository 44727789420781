import React, { HTMLAttributes, useEffect, useRef, useState } from "react";
import { AriaListBoxProps, VisuallyHidden } from "react-aria";
import { Item, useListState } from "react-stately";

import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import { Clickable } from "../Clickables";
import { Svg } from "../Svg";

import styles from "./ListCarousel.module.scss";

type CarouselProps = AriaListBoxProps<Record<string, never>> & {
    className?: string;
    style?: React.CSSProperties | undefined;
    wrapperAttrs?: HTMLAttributes<HTMLDivElement>;
    listItemAttrs?: HTMLAttributes<HTMLLIElement>;
    navigationProps?: {
        size?: "large" | "small";
        className?: string;
    };
    progressClassName?: string;
};

interface CustomCss extends React.CSSProperties {
    "--scroll-progress-decimal": number;
    "--scroll-progress-percent": string;
}

/**
 * ListCarousel outputs a carousel as an unordered list, and relies heavily on
 * IntersectionObserver to update the markup based on what is visible on screen.
 *
 * The reason why this exists is that Carousel uses the tab aria role, which
 * doesn't work if the carousel requires more than a single slide (tab) to be
 * visible at the same time. (The underlying code uses `inert` for inactive
 * tabs, which causes problems when the inert thing is visible and you want to
 * click it).
 *
 * This markup heavily follows the example provided by WAI's carousel tutorial
 * https://www.w3.org/WAI/tutorials/carousels/, while incorporating
 * aria-roledescription, which did not exist at the time, as the author notes:
 * https://github.com/w3c/wai-tutorials/issues/594#issuecomment-530309064)
 *
 */
export function ListCarousel({
    autoFocus,
    wrapperAttrs,
    listItemAttrs,
    label,
    navigationProps = {
        size: "large",
    },
    progressClassName,
    ...containerProps
}: CarouselProps) {
    /**
     * If you don't wrap in <Item>, an error is thrown:
     * `type.getCollectionNode is not a function`
     * This abstracts the problem away.
     */
    let itemChildren = containerProps.children;

    if (typeof containerProps.children === "function") {
        console.error("If you want this, make it work");
    } else {
        itemChildren = Array.isArray(containerProps.children) ? (
            containerProps.children.map((child, index) => (
                <Item
                    textValue="unused"
                    key={child.key || `list-carousel-item-${index}`}
                >
                    {child}
                </Item>
            ))
        ) : (
            <Item
                textValue="unused"
                key={containerProps.children.key || "list-carousel-item"}
            >
                {containerProps.children}
            </Item>
        );
    }

    const state = useListState({
        selectionMode: "multiple",
        selectionBehavior: "replace",
        ...containerProps,
        children: itemChildren,
    });

    const wrapper = useRef<HTMLDivElement>(null);
    const list = useRef<HTMLUListElement>(null);

    const [prevScrollBehavior, setPrevScrollBehavior] = useState<
        "last" | "adjacent"
    >("adjacent");
    const [nextScrollBehavior, setNextScrollBehavior] = useState<
        "first" | "adjacent"
    >("adjacent");
    const [itemWidth, setItemWidth] = useState(10);
    const [allVisible, setAllVisible] = useState(true);
    const [scrollProgress, setScrollProgress] = useState<number>(0);

    /**
     * When clicking previous or next buttons, it should advance by one slide
     * forward or backwards; CSS scroll snapping plus smooth scrolling here
     * is making that look clean.
     *
     * But there are situations where we want to jump to the beginning or end;
     * this uses prevScrollBehavior and nextScrollBehavior, which is set by
     * the intersection observer.
     */
    const scroll = (left: number) => {
        if (!wrapper.current) return;
        const ref = wrapper.current;

        if (nextScrollBehavior === "first" && left > 0) {
            ref.scrollTo({ left: 0, behavior: "smooth" });
        } else if (prevScrollBehavior === "last" && left < 0) {
            ref.scrollTo({ left: ref.scrollWidth, behavior: "smooth" });
        } else {
            ref.scrollBy({ left, behavior: "smooth" });
        }
    };

    useEffect(() => {
        if (!list.current) return;
        const elementsToWatch = list.current.querySelectorAll("li");

        /**
         * There are some oddities here because state.selectionManager.setSelectedKeys()
         * needed to have `state` passed into the effect to work properly, but doing
         * that reinitializes the observer. Was just looking for ways to cut down
         * rerenders overall.
         */
        const observer = new IntersectionObserver(
            (entries) => {
                /**
                 * A single entry usually means that an entry has updated, usually
                 * for isIntersecting to toggle. We don't need everything to change
                 * when that happens.
                 */
                const singleEntry = entries.length === 1;

                /**
                 * To distinguish between an updating entry and a collection of one
                 */
                const onlyOneKey = state.collection.size === 1;

                /** Anything that's visible should be "selected" */
                const visibleEntryIds = entries
                    .filter((entry) => entry.isIntersecting)
                    .map((entry) => entry.target.id);
                /**
                 * Assumption: at least one slide will always be fully visible.
                 * Happy accident, I think?: the slide going offscreen doesn't
                 * lose focus until the next slide coming on is visible.
                 */
                if (visibleEntryIds.length > 0) {
                    state.selectionManager.setSelectedKeys(visibleEntryIds);
                }

                /**
                 * If the first item is visible, then clicking the previous
                 * button should take you to the end of the carousel
                 */
                const firstIsVisible =
                    entries.find(
                        (entry) =>
                            entry.target.id === state.collection.getFirstKey(),
                    )?.isIntersecting || false;
                if (!singleEntry) {
                    setPrevScrollBehavior(firstIsVisible ? "last" : "adjacent");
                }

                /**
                 * If the last item is visible, then clicking the next
                 * button should take you to the beginning of the carousel
                 */
                const lastIsVisible =
                    entries.find(
                        (entry) =>
                            entry.target.id === state.collection.getLastKey(),
                    )?.isIntersecting || false;
                if (!singleEntry) {
                    setNextScrollBehavior(lastIsVisible ? "first" : "adjacent");
                }

                /**
                 * If all items are visible, the button nav is unnecessary
                 */
                const notVisibleCount = entries.filter(
                    (entry) => !entry.isIntersecting,
                ).length;
                /**
                 * If there's only one item, it should be visible, and we
                 * shouldn't need nav anyways
                 */
                if (singleEntry && onlyOneKey) {
                    setAllVisible(true);
                } else if (!singleEntry) {
                    setAllVisible(notVisibleCount === 0);
                }
            },
            { threshold: 1 },
        );
        elementsToWatch.forEach((element) => observer.observe(element));

        return () => {
            elementsToWatch.forEach((element) => observer.unobserve(element));
        };
    }, [state]);

    /**
     * This shouldn't be necessary, but Safari on iOS doesn't pay attention to
     * scroll snapping when using scrollBy. This isn't perfect because it
     * assumes that every item is the same width and it doesn't account for margins,
     * but I figure it's passable.
     */
    useEffect(() => {
        const onResize = () =>
            setItemWidth(list.current?.querySelector("li")?.clientWidth || 10);

        onResize();
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, []);

    useEffect(() => {
        if (!progressClassName) return;
        const onScroll = () => {
            setScrollProgress(
                (wrapper.current!.scrollLeft + wrapper.current!.clientWidth) /
                    wrapper.current!.scrollWidth,
            );
        };

        onScroll();
        if (wrapper.current)
            wrapper.current.addEventListener("scroll", onScroll);

        return () => {
            if (wrapper.current) window.removeEventListener("resize", onScroll);
        };
    }, []);

    const activeIndexes = [...state.selectionManager.selectedKeys].map(
        (key) => `${[...state.collection.getKeys()].indexOf(key) + 1}`,
    );

    /* eslint-disable */
    const listFormat =
        // @ts-expect-error: Not aware of `ListFormat`
        typeof Intl.ListFormat !== "undefined"
            ? // @ts-expect-error: 🙄
              new Intl.ListFormat("en")
            : null;
    const prettyActiveIndexes: string = listFormat
        ? listFormat.format(activeIndexes)
        : activeIndexes.join(", ");
    /* eslint-enable */

    return (
        <>
            <div
                className={concatClassNames([
                    styles.controls,
                    navigationProps.className,
                ])}
                style={{ display: allVisible ? "none" : undefined }}
            >
                <Clickable
                    className={concatClassNames([
                        styles.navArrow,
                        styles.previous,
                    ])}
                    aria-label={
                        prevScrollBehavior === "last"
                            ? "Go to last tab"
                            : "Previous tab"
                    }
                    onPress={() => scroll(-itemWidth)}
                >
                    <Svg name="caret-right" />
                </Clickable>
                <Clickable
                    aria-label={
                        nextScrollBehavior === "first"
                            ? "Go to first tab"
                            : "Next tab"
                    }
                    onPress={() => scroll(itemWidth)}
                    className={styles.navArrow}
                >
                    <Svg name="caret-right" />
                </Clickable>
            </div>
            <div
                {...wrapperAttrs}
                className={concatClassNames([
                    styles.wrapperHorizontalScroll,
                    wrapperAttrs?.className,
                ])}
                ref={wrapper}
            >
                <div aria-live="polite" aria-atomic="true">
                    {activeIndexes.length > 0 && (
                        <VisuallyHidden>
                            Showing items {prettyActiveIndexes} of{" "}
                            {state.collection.size}
                        </VisuallyHidden>
                    )}
                </div>

                <ul
                    {...containerProps}
                    className={concatClassNames([
                        styles.ulHorizontalScroll,
                        containerProps.className,
                    ])}
                    role="group"
                    aria-roledescription="carousel"
                    aria-label={label?.toString()}
                    ref={list}
                >
                    {[...state.collection].map((item) => (
                        <li
                            {...listItemAttrs}
                            className={concatClassNames([
                                styles.liHorizontalScroll,
                                listItemAttrs?.className,
                            ])}
                            // @ts-expect-error: Not aware of `inert`
                            inert={
                                !state.selectionManager.isSelected(item.key)
                                    ? ""
                                    : undefined
                            }
                            id={item.key.toString()}
                            key={item.key}
                        >
                            {item.rendered}
                        </li>
                    ))}
                </ul>
            </div>
            {/**
             * Progress bar. Will not show unless a class is passed in, to prevent
             * unnecessary scroll event firing. Hidden from aria because I don't
             * think it adds anything - we already have a live section that says
             * "Showing items 1 of 6" or whatever it is, so a progress percent
             * feels redundant.
             */}
            {progressClassName && (
                <div
                    className={progressClassName}
                    style={
                        {
                            "--scroll-progress-decimal": scrollProgress,
                            "--scroll-progress-percent": `${scrollProgress * 100}%`,
                        } as CustomCss
                    }
                    aria-hidden="true"
                />
            )}
        </>
    );
}
